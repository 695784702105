import './Hero2.css';

function Hero2() {
  return (
    <section className="hero2--section">
      <div className="hero2--content">
        <h1>Specialized packaging for your business.</h1>
        <h4>Cardboard | Wood | Plastics | Hybrid Packaging</h4>
      </div>
    </section>
  );
}

export default Hero2;