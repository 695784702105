import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import './Inventory.css';
import image from '../img/img3.jpeg';

function Inventory() {

  useEffect(() => {
    Aos.init({duration: 1500, easing: "ease-in-out", once: true});
  }, []);

  return (
    <section className="inventory--section">
      <div className="container inventory--content">
        <div data-aos="fade-right" className="inventory--content--left">
          <h3>We manage your inventory.</h3>
          <h2>We maintain an inventory of products to be delivered when required.</h2>
        </div>
        <div className="inventory--content--right">
          <img data-aos="zoom-in" src={image} alt="CAERLSA inventory" />
        </div>
      </div>
    </section>
  )
}

export default Inventory
