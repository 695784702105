import { FaLinkedinIn } from 'react-icons/fa';
import './Contact.css';

function Contact() {
  return (
    <section className="contact--section">
      <div className="container contact--content">
        <h1>Let's work <span className="newline">together</span></h1>
        <a href="mailto:ventas@caerlsa.com">ventas@caerlsa.com</a>
        <a href="tel:+528110662252">(+52) 8110662252</a>
        <a href="https://goo.gl/maps/3UugoSorvk6CG6i78" target="_blank" rel="noreferrer">417 Vallecillo Colonia Jesús María <span className="newline">Pesquería, Nuevo León, MX 66650</span></a>
        <a href="https://www.linkedin.com/company/caerlsa-conversiones-y-asesor%C3%ADa-en-empaque-rl/" target="_blank" rel="noreferrer" className="contact--icon"><FaLinkedinIn /></a>
      </div>
    </section>
  )
}

export default Contact;