import React, { useState, useEffect } from 'react';
import ScaleLoader from "react-spinners/ScaleLoader";
import './Home.css';
import NavBar from '../components/NavBar';
import Hero from '../components/Hero';
import Clients from '../components/Clients';
import Work from '../components/Work';
import Hero2 from '../components/Hero2';
import Inventory from '../components/Inventory';
import Images from '../components/Images';
import Certification from '../components/Certification';
import Contact from '../components/Contact';
import Footer from '../components/Footer';

function Home() {
  const [loading, setLoading] = useState(true);
  const handleLoading = () => {
    setLoading(false);
  }

  useEffect(() => {
    window.addEventListener("load", handleLoading);
    return () => {
      window.removeEventListener("load", handleLoading);
    };
  }, []);

  return <>
  {
    loading ? 
    <div style={style.root}>
      <ScaleLoader size={150} color={"#18A940"} loading={loading} />
    </div>

    :
    <>
      <NavBar />
      <Hero />
      <Clients />
      <Work />
      <Hero2 />
      <Inventory />
      <Images />
      <Certification />
      <Contact />
      <Footer />
    </>
  }
    
  </>
}

const style = {"root": {
  display: "flex",
  height: "100%",
  justifyContent: "center",
  alignItems: "center"
}}

export default Home;