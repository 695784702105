import './Hero.css';

function Hero() {
  return (
    <section className="hero--section">
      <div className="hero--content">
        <h1>We design and build the best protection for your products.</h1>
        <p>25 years taking care of our clients' products.</p>
      </div>
    </section>
  )
}

export default Hero;