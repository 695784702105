import './Footer.css';

function Footer() {
  return (
    <footer className="footer--wrapper">
      <p>© 2021 Conversiones y Asesoría en Empaque RL, S.A. de C.V. All Rights Reserved.</p>
      <a href="https://www.lavocat.mx" target="_blank" rel="noreferrer">Design by Lavocat.mx</a>
    </footer>
  );
}

export default Footer;