import './Work.css';

function Work() {
  return (
    <section className="work--section">
      <div className="container work--content">
        <h3>Our way of working:</h3>
        <div className="work--content--elements">
          <div className="work--element">
            <h2>Innovation + <span className="newline">Strategy</span></h2>
            <p>We understand your product requirements and develop the best packaging solution aligned to your company strategy. An empathic design for your product, your clients and your industry.</p>
          </div>
          <div className="work--element">
            <h2>Agile Design + <span className="newline">Manufacture</span></h2>
            <p>The key is to resolve fast and manufacture twice as fast so that you do not have to worry about the supply chain.</p>
          </div>
          <div className="work--element">
            <h2>High Quality + <span className="newline">Service</span></h2>
            <p>Our company will produce and deliver the volume you need on the day and time you require it.</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Work
