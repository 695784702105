import './Certification.css';

function Certification() {
  return (
    <section className="certification--section">
      <div className="container certification--content">
        <p>For the last months, we have been working together as a company to meet all the requirements needed to get certified and prove we are the best option for you.</p>
      </div>
    </section>
  );
}

export default Certification;