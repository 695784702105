import Carousel from 'react-bootstrap/Carousel';
import './Images.css'
import img1 from '../img/img4.jpg';
import img2 from '../img/img5.png';
import img3 from '../img/img6.jpg';
import img4 from '../img/img7.png';
import img5 from '../img/img8.png';
import img6 from '../img/img9.png';
import img7 from '../img/img10.png';
import img8 from '../img/img11.jpg';
import img9 from '../img/img12.jpg';

function Images() {
  return (
    <section className="images--section">
      <div className="images--content">
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={img1}
              alt="First slide"
            />
          </Carousel.Item>        
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={img2}
              alt="First slide"
            />
          </Carousel.Item>        
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={img3}
              alt="First slide"
            />
          </Carousel.Item>        
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={img4}
              alt="First slide"
            />
          </Carousel.Item>        
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={img5}
              alt="First slide"
            />
          </Carousel.Item>        
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={img6}
              alt="First slide"
            />
          </Carousel.Item>        
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={img7}
              alt="First slide"
            />
          </Carousel.Item>        
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={img8}
              alt="First slide"
            />
          </Carousel.Item>        
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={img9}
              alt="First slide"
            />
          </Carousel.Item>        
        </Carousel>
      </div>
    </section>
  )
}

export default Images;
