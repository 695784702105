import './Clients.css';
import image from '../img/img1.png';

function Clients() {
  return (
    <section className="clients--section">
      <div className="container clients--content">
        <div className="clients--content--left">
          <img src={image} alt="CAERLSA workers in production" />
        </div>
        <div className="clients--content--right">
          <p>We are experts in designing and manufacturing Packaging for the Automotive, Electronics, Home Appliances and Medical Industry.</p>
          <p>Our business model is based on the excellence of service to those who trust in us, including: </p>
          <p>DENSO CORPORATION | GE MEDICAL | MAGNA INTERNATIONAL | MITSUBA | TOYOTA among others.</p>
        </div>
      </div>
    </section>
  );
}

export default Clients;