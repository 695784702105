import React, { useState, useEffect } from 'react';
import { FaLinkedinIn } from 'react-icons/fa';
import './NavBar.css'
import icon from '../img/caerlsa.png';

function NavBar() {

  const [show, setShow] = useState(false);
  const [top, setTop] = useState(true);
  let prevScroll = 0;

  const controlNavBar = () => {
    if (window.scrollY - prevScroll > 150) {
      prevScroll = window.scrollY;
      setShow(false);
    } else if (window.scrollY - prevScroll < -150) {
      prevScroll = window.scrollY;
      setShow(true);
    }

    if (window.scrollY < 150) {
      setTop(true);
    } else {
      setTop(false);
    }
  }

  useEffect(() => {
    setShow(true);
    window.addEventListener('scroll', controlNavBar);
    return () => {
      window.removeEventListener('scroll', controlNavBar);
    };
  }, []);

  return (
    <header className={`nav-bar--header ${show && 'active'} ${top && 'top'}`}>
      <a href="https://www.caerlsa.com">
        <img src={icon} alt="CAERLSA logo" />
      </a>
      
      <a href="https://www.linkedin.com/company/caerlsa-conversiones-y-asesor%C3%ADa-en-empaque-rl/" target="_blank" rel="noreferrer"><FaLinkedinIn /></a>
    </header>
  );
}

export default NavBar;

// Me quede haciendo la animacion de la navbar apra ocultar y mostrar en los scrolls